import {
    SIGN_UP_GALLERY,
    SIGN_UP_GALLERY_TABS,
    SubscriptionInterval,
} from '@/assets/static/signupGallery'
import SubscriptionPlans from './SubscriptionPlans'
import { useEffect, useRef, useState } from 'react'
import { getSubscriptionTypes } from '@/api/subscription'
import { useQuery } from '@tanstack/react-query'
import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import { Button } from '@/components/Button'
import goTrueClient from '@/api/goTrueClient'
import { useNavigate } from 'react-router-dom'

const TerminatedSubscription = () => {
    const [interval, setInterval] = useState<SubscriptionInterval>('month')

    const tableRef = useRef<HTMLDivElement>(null)
    const [showScrollTopButton, setShowScrollTopButton] = useState(false)
    const [showScrollDownButton, setShowScrollDownButton] = useState(false)

    const navigate = useNavigate()

    const { data: authSession } = useQuery({
        enabled: true,
        queryKey: ['session'],
        queryFn: () => goTrueClient.getSession(),
    })

    const { data, error, isLoading } = useQuery({
        queryKey: ['subscription-types', interval],
        queryFn: () => getSubscriptionTypes(interval),
    })

    useEffect(() => {
        const tableElement = tableRef.current
        if (tableElement) {
            const handleScroll = () => {
                setShowScrollTopButton(tableElement.scrollTop > 200)
                setShowScrollDownButton(
                    tableElement.scrollHeight - tableElement.scrollTop - tableElement.clientHeight >
                        200,
                )
            }
            tableElement.addEventListener('scroll', handleScroll)
            return () => {
                tableElement.removeEventListener('scroll', handleScroll)
            }
        }
    }, [data])

    if (isLoading) return <div>Loading...</div>

    if (error) return <div>Error loading subscriptions. Please try again later.</div>

    const filteredData = data?.filter(
        (subscription) => subscription.interval === interval || subscription.interval === 'days',
    )

    if (!filteredData || filteredData?.length === 0) return <div>No subscriptions available.</div>

    const scrollToTop = () => {
        if (tableRef.current) {
            tableRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    const scrollToBottom = () => {
        if (tableRef.current) {
            tableRef.current.scrollTo({ top: tableRef.current.scrollHeight, behavior: 'smooth' })
        }
    }

    return (
        <div className='flex flex-col gap-5'>
            <div className='flex flex-col gap-5 text-center justify-center px-20'>
                <p className='text-2xl font-semibold text-zentive-green-dark'>
                    Your Billing has been Paused
                </p>
                <p>
                    Your billing is paused as you have canceled your renewal. To continue using our
                    services without interruption, please resubscribe to your current plan or choose
                    a new plan below.
                </p>
                <div>
                    <Button
                        onClick={() =>
                            navigate(
                                `/renewal/payment?planid=${authSession?.data?.session?.user?.user_metadata?.subscriptionTypeId}`,
                            )
                        }
                        className='w-[25%] bg-zentive-blue-dark'
                    >
                        Resubscribe
                    </Button>
                </div>
            </div>
            <div
                ref={tableRef}
                className='h-[460px] overflow-y-auto no-scrollbar relative bg-white pt-5'
            >
                <p className='text-2xl text-center justify-center text-zentive-green-dark font-semibold'>
                    Pricing
                </p>
                <div className='flex justify-center mt-4 mb-8'>
                    <Tabs
                        defaultValue='month'
                        onValueChange={(val) => setInterval(val as SubscriptionInterval)}
                        className='w-[400px]'
                    >
                        <TabsList className='grid w-full h-auto grid-cols-2 rounded-full bg-[#0C3D3C]'>
                            {SIGN_UP_GALLERY_TABS?.map((item) => (
                                <TabsTrigger
                                    className={item.className}
                                    key={item.title}
                                    value={item.value}
                                >
                                    {item.title}
                                </TabsTrigger>
                            ))}
                        </TabsList>
                    </Tabs>
                </div>
                <SubscriptionPlans
                    subscriptionTypes={filteredData}
                    tableData={SIGN_UP_GALLERY[interval]}
                    interval={interval}
                />
            </div>

            {showScrollTopButton && (
                <button
                    className='fixed bottom-20 right-10 p-4 bg-[#0C3D3C] text-white rounded-full shadow-lg flex items-center justify-center'
                    onClick={scrollToTop}
                    style={{ width: '50px', height: '50px' }}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth={2}
                    >
                        <path strokeLinecap='round' strokeLinejoin='round' d='M5 15l7-7 7 7' />
                    </svg>
                </button>
            )}
            {showScrollDownButton && (
                <button
                    className='fixed bottom-20 right-10 p-4 bg-[#0C3D3C] text-white rounded-full shadow-lg flex items-center justify-center'
                    onClick={scrollToBottom}
                    style={{ width: '50px', height: '50px' }}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth={2}
                    >
                        <path strokeLinecap='round' strokeLinejoin='round' d='M19 9l-7 7-7-7' />
                    </svg>
                </button>
            )}
        </div>
    )
}

export default TerminatedSubscription
